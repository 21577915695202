import { MusicTitleSessionConfigurationEntity } from './MusicTitleSessionConfigurationEntity';
import { MusicVolumeSessionConfigurationEntity } from './MusicVolumeSessionConfigurationEntity';

export class MusicSessionConfigurationEntity {
  public enabled: {lobby: number, countdownRunning: number, countdownEnd: number};
  public shared: {lobby: number, countdownRunning: number, countdownEnd: number};

  public volumeConfig: MusicVolumeSessionConfigurationEntity;
  public titleConfig: MusicTitleSessionConfigurationEntity;

  constructor(settingsService, props) {
    this.enabled = props?.enabled ?? {
      lobby: settingsService.frontEnv?.defaultQuizSettings.sessionConfig.music.enabled.lobby,
      countdownRunning: settingsService.frontEnv?.defaultQuizSettings.sessionConfig.music.enabled.countdownRunning,
      countdownEnd: settingsService.frontEnv?.defaultQuizSettings.sessionConfig.music.enabled.countdownEnd,
    };
    this.shared = props?.shared ?? {
      lobby: settingsService.frontEnv?.defaultQuizSettings.sessionConfig.music.shared.lobby,
      countdownRunning: settingsService.frontEnv?.defaultQuizSettings.sessionConfig.music.shared.countdownRunning,
      countdownEnd: settingsService.frontEnv?.defaultQuizSettings.sessionConfig.music.shared.countdownEnd,
    };
    this.volumeConfig = props?.volumeConfig ?? settingsService.frontEnv?.defaultQuizSettings.sessionConfig.music.volumeConfig;
    this.titleConfig = props?.titleConfig ?? settingsService.frontEnv?.defaultQuizSettings.sessionConfig.music.titleConfig;
  }

  public equals(value: MusicSessionConfigurationEntity): boolean {
    return (this.volumeConfig.global === value.volumeConfig.global && //
            this.volumeConfig.lobby === value.volumeConfig.lobby && //
            this.volumeConfig.countdownRunning === value.volumeConfig.countdownRunning && //
            this.volumeConfig.countdownEnd === value.volumeConfig.countdownEnd && //
            this.titleConfig.lobby === value.titleConfig.lobby && //
            this.titleConfig.countdownRunning === value.titleConfig.countdownRunning && //
            this.titleConfig.countdownEnd === value.titleConfig.countdownEnd && //
            this.enabled.lobby === value.enabled.lobby && //
            this.enabled.countdownRunning === value.enabled.countdownRunning && //
            this.enabled.countdownEnd === value.enabled.countdownEnd && //
            this.shared.lobby === value.shared.lobby && //
            this.shared.countdownRunning === value.shared.countdownRunning && //
            this.shared.countdownEnd === value.shared.countdownEnd //
    );
  }
}
