import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MarkdownService} from 'ngx-markdown';
import {MotdDataService} from '../../service/motd/motd-data.service';

@Component({
  selector: 'app-motd-modal',
  templateUrl: './motd-modal.component.html',
  styleUrls: ['./motd-modal.component.scss']
})
export class MotdModalComponent implements OnInit {

  constructor(private ngbModal: NgbActiveModal, public motdData: MotdDataService, public  markdownservice: MarkdownService) {
  }

  public ngOnInit(): void {
  }

  public dismiss(): void {
    this.ngbModal.dismiss();
  }

  public compileMarkDownContent(toCompile: string): string {
    return this.markdownservice.compile(toCompile);
  }

}
