<div *ngIf="statistics"
     class="statistics-wrapper card d-flex p-2 mb-1 flex-column flex-md-row flex-nowrap justify-content-around align-items-center">
  <div *ngFor="let elem of data"
       class="button-wrapper container-fluid container-md px-0 mx-md-1 mx-xl-4 my-1 my-md-0 d-flex justify-content-md-center justify-content-between align-items-center">
      <button
              type="button"
              class="btn statistic-btn d-flex btn-block btn-md align-items-center justify-content-between justify-content-md-center btn-outline-primary"
              placement="top"
              [ngbPopover]="elem.content | translate" >
        <div
                class="d-flex justify-content-center align-items-center">
          <div [style.color]="elem.iconColor"
               class="icon-wrapper pr-2 pr-md-0 statistics-icon-opacity">
            <fa-layers *ngIf="elem.iconLayer">
              <fa-icon *ngFor="let icon of elem.iconLayer"
                       [icon]="icon.classes"
                       [mask]="icon.mask"
                       [transform]="icon.transform"
                       size="1x"></fa-icon>
            </fa-layers>
            <fa-icon *ngIf="!elem.iconLayer"
                     [icon]="elem.icon"
                     size="1x"></fa-icon>
          </div>
          <div>
            <fa-icon *ngIf="isLoading" [spin]="true" [icon]="'spinner'" size="1x" class="loading-icon"></fa-icon>
            <h5 *ngIf="!isLoading" class="mb-0">{{elem.amount}}</h5>
          </div>
        </div>
        <p class="d-md-none px-2">
          {{elem.title | translate}}
        </p>
      </button>
  </div>
</div>
