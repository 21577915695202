<div *ngIf="!config?.hideControls"
     class="btn-toolbar flex-grow-1 flex-nowrap">

  <div class="btn-group d-flex align-items-center">

    <button *ngIf="!isPlaying"
            class="btn btn-secondary"
            (click)="playMusic()">
      <fa-icon [icon]="'play'"></fa-icon>
    </button>
    <button *ngIf="isPlaying"
            class="btn btn-secondary"
            (click)="pauseMusic()">
      <fa-icon [icon]="'pause'"></fa-icon>
    </button>
    <button class="btn btn-secondary"
            (click)="stopMusic()"
            [disabled]="isStopped()">
      <fa-icon [icon]="'stop'"></fa-icon>
    </button>

  </div>

  <div class="input-group flex-grow-1 align-items-center flex-nowrap">
    <input type="range"
           class="flex-grow-1"
           [(ngModel)]="volume">

    <span class="ml-2 text-nowrap">{{volume}} %</span>
  </div>

</div>

<button *ngIf="config?.autostart && autostartRejected"
        class="btn btn-invers btn-sm"
        (click)="playMusic()">
  <fa-icon class="mr-2" [icon]="'play'"></fa-icon>
  <span>Musik abspielen</span>
</button>
