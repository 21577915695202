import {ErrorHandler, Injectable} from '@angular/core';
import {captureException, Event as SentryEvent, init as SentryInit, setExtra} from '@sentry/browser';
import {EventHint} from '@sentry/types';
import {SettingsService} from '../service/settings/settings.service';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class SentryErrorHandler implements ErrorHandler {
    private _enabled;

    constructor(private settingsService: SettingsService) {
        settingsService.settingsLoadedEmitter.subscribe(status => {
            if (status) {
                this._enabled = !!this.settingsService.frontEnv?.sentryDSN;
                if (!this._enabled) {
                    return;
                }

                SentryInit({
                    dsn: this.settingsService.frontEnv?.sentryDSN,
                    enabled: this._enabled,
                    release: environment.version,
                    beforeSend(event: SentryEvent, hint?: EventHint): PromiseLike<SentryEvent | null> | SentryEvent | null {
                        if (event.exception.values.some(val => !val.mechanism.handled)) {
                            console.log('sending error event', event, hint);
                            return event;
                        }
                        return null;
                    },
                });

                setExtra('nonErrorException', false);
            }
        });
    }

    public handleError(error): void {
        captureException(error.originalError || error);
        throw error;
    }
}
