import { MusicSessionConfigurationEntity } from './MusicSessionConfigurationEntity';
import { NickSessionConfigurationEntity } from './NickSessionConfigurationEntity';

export class SessionConfigurationEntity {
  public music: MusicSessionConfigurationEntity;
  public nicks: NickSessionConfigurationEntity;
  public theme: string;
  public readingConfirmationEnabled: boolean;
  public showResponseProgress: boolean;
  public confidenceSliderEnabled: boolean;

  constructor(settingsService, props) {
    this.theme = settingsService.frontEnv?.defaultQuizSettings.sessionConfig.theme;
    this.readingConfirmationEnabled = settingsService.frontEnv?.defaultQuizSettings.sessionConfig.readingConfirmationEnabled;
    this.showResponseProgress = settingsService.frontEnv?.defaultQuizSettings.sessionConfig.showResponseProgress;
    this.confidenceSliderEnabled = settingsService.frontEnv?.defaultQuizSettings.sessionConfig.confidenceSliderEnabled;

    this.music = new MusicSessionConfigurationEntity(settingsService, props.music);
    this.nicks = new NickSessionConfigurationEntity(settingsService, props.nicks);
    this.theme = props.theme;
    this.readingConfirmationEnabled = props.readingConfirmationEnabled;
    this.showResponseProgress = props.showResponseProgress;
    this.confidenceSliderEnabled = props.confidenceSliderEnabled;
  }

  public equals(value: SessionConfigurationEntity): boolean {
    return this.music.equals(value.music) && this.nicks.equals(value.nicks) && this.theme === value.theme && this.readingConfirmationEnabled
           === value.readingConfirmationEnabled && this.showResponseProgress === value.showResponseProgress && this.confidenceSliderEnabled
           === value.confidenceSliderEnabled;
  }
}
