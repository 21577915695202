import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IEnvironment } from '../../lib/settings/IEnvironment';
import { SettingsApiService } from './settings-api.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public frontEnv: IEnvironment;
  public settingsLoadedEmitter = new ReplaySubject<boolean>(1);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private settingsApi: SettingsApiService,
  ) {
  }

  public initServerSideLoadedSettings(): Observable<IEnvironment> {
    return this.settingsApi.getEnvSettings().pipe(
      catchError(err => {
        if (isPlatformServer(this.platformId)) {
          return of(null);
        }
        console.log(err);
        return of(JSON.parse(localStorage.getItem('settings')));
      }),
      tap(env => {
        // Workaround required because JSON serializes Infinity to null which is then deserialized to NaN by EcmaScript
        if (env?.limitActiveQuizzes === null) {
          env.limitActiveQuizzes = Infinity;
        }
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('settings', JSON.stringify(env));
        }
        this.frontEnv = env;
        this.settingsLoadedEmitter.next(true);
      }),
    );
  }
}
