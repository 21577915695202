import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DefaultSettings } from '../../lib/default.settings';
import { IEnvironment } from '../../lib/settings/IEnvironment';

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService {
  constructor(private http: HttpClient) {
  }

  public getEnvSettings(): Observable<IEnvironment> {
    return this.http
      .get<{ serverConfig: IEnvironment }>(`${DefaultSettings.httpApiEndpoint}/`)
      .pipe(
        map(settings => settings.serverConfig),
      );
  }
}
