import { environment } from '../../environments/environment';

function stompEndpointFactory(): string {
  if (typeof location === 'undefined') {
    return null;
  }

  if (environment.stompConfig.endpoint.startsWith('/')) {
    return location.protocol.replace('http', 'ws') + location.host + environment.stompConfig.endpoint;
  }

  return environment.stompConfig.endpoint;
}

export const DefaultSettings = {
  siteId: 'arsnova.click-v2',
  httpApiEndpoint: environment.serverEndpoint + '/api/v1',
  httpLibEndpoint: environment.serverEndpoint + '/api/lib',
  stompEndpoint: stompEndpointFactory(),
};
