import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { DefaultSettings } from '../../lib/default.settings';
import { IMotdEntity } from '../../lib/entities/motd/MotdEntity';
import { MotdModalComponent } from '../../modals/motd-modal/motd-modal.component';
import { MotdDataService } from './motd-data.service';

@Injectable({
  providedIn: 'root',
})
export class MotdService {
  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId, private ngbModal: NgbModal, private motdData: MotdDataService) {
  }

  public getAllMotds(): Observable<Array<IMotdEntity>> {
    return this.http.get<Array<IMotdEntity>>(`${DefaultSettings.httpApiEndpoint}/admin/motd`);
  }

  public showMotdModal(force = false): Promise<void> {
    if (this.motdData.allMotds || force) {
      this.motdData.unseenMotds = [...this.motdData.getUnseenMotds(), ...this.motdData.getPinnedMotds()];

      if (this.motdData.unseenMotds?.length || force) {
        if (this.motdData.unseenMotds?.length) {
          this.motdData.saveLastSeenMotdId(this.motdData.allMotds.sort((a, b) => b.mid - a.mid)[0].mid);
        }
        return this.ngbModal.open(MotdModalComponent, { size: 'lg' }).result.then(() => {
        }).catch(() => {
        });
      }
    }
  }
}
