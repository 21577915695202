import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {IMotdEntity} from '../../lib/entities/motd/MotdEntity';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MotdDataService {

  public allMotds: IMotdEntity[] = [];
  public unseenMotds: IMotdEntity[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  public saveLastSeenMotdId(motdId: number): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('lastSeenMotdId', motdId.toString());
    }
  }

  public getLastSeenMotdId(): number {
    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.getItem('lastSeenMotdId')) {
        return -1;
      }
      return parseInt(localStorage.getItem('lastSeenMotdId'), 10);
    }
  }

  public getUnseenMotds(): IMotdEntity[] {
    const lastSeenMotdId = this.getLastSeenMotdId();
    return this.allMotds?.filter(m => m.mid > lastSeenMotdId);
  }

  public getPinnedMotds(): IMotdEntity[] {
    return this.allMotds?.filter(m => m.isPinned);
  }
}
