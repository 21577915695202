<ng-container *ngIf="!isLoading">
  <h4 *ngIf="hasTokenLogin" class="text-center mb-3">{{'component.login.login-to-quiz' | translate}}</h4>
  <h4 *ngIf="!hasTokenLogin" class="text-center mb-3">{{'component.login.login' | translate}}</h4>
  <h4 class="text-center mb-5 mt-sm-5">{{'component.login.title' | translate}}</h4>

  <div *ngIf="hasUsernamePasswordLogin"
       class="input-group">

    <input (keydown.enter)="login('password')"
           [(ngModel)]="username"
           [placeholder]="'component.login.username' | translate"
           class="form-control my-2 my-sm-0"
           name="username"
           type="text"/>

    <input (keydown.enter)="login('password')"
           [(ngModel)]="password"
           [placeholder]="'component.login.password' | translate"
           class="form-control my-2 my-sm-0"
           name="password"
           type="password"/>

    <div class="input-group-append">

      <button (click)="login('password')"
              [disabled]="isLoggingIn"
              class="btn btn-light">
        <fa-icon *ngIf="isLoggingIn === 'password'"
                 [icon]="'spinner'"
                 [spin]="true"
                 class="mr-2"></fa-icon>
        <span>{{'component.login.login' | translate}}</span>
      </button>

    </div>
  </div>

  <ng-container *ngIf="hasTokenLogin">

    <div class="input-group">

      <input (keydown.enter)="login('token')"
             [(ngModel)]="token"
             [placeholder]="'component.login.token' | translate"
             class="form-control my-2 my-sm-0"
             name="token"
             type="password"/>

      <div class="input-group-append">

        <button (click)="login('token')"
                [disabled]="isLoggingIn"
                class="btn btn-light">
          <fa-icon *ngIf="isLoggingIn === 'token'"
                   [icon]="'spinner'"
                   [spin]="true"
                   class="mr-2"></fa-icon>
          <span>{{'component.login.login' | translate}}</span>
        </button>

      </div>
    </div>
  </ng-container>

  <div class="mt-5">
    <ngb-alert *ngIf="authorizationFailed"
               [dismissible]="false"
               type="danger">
      <h5 class="text-center mb-0">{{'component.login.authorization_failed' | translate}}</h5>
    </ngb-alert>
  </div>
</ng-container>
