import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Title as BrowserTitle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '../../lib/enums/enums';
import {SettingsService} from '../settings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderLabelService {
  private _headerLabelParams = {};
  private _headerLabel: Title | string = Title.Default;
  private _subHeader: string;

  public isUnavailableModalOpen: boolean;

  get headerLabelParams(): Object {
    return this._headerLabelParams;
  }

  set headerLabelParams(value: Object) {
    this._headerLabelParams = value;
    this.regenerateTitle();
  }

  get headerLabel(): Title | string {
    return this._headerLabel;
  }

  set headerLabel(value: Title | string) {
    this._headerLabel = value;
    this.regenerateTitle();
  }

  get subHeader(): string {
    return this._subHeader;
  }

  set subHeader(value: string) {
    this._subHeader = value;
    this.regenerateTitle();
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translateService: TranslateService,
    private titleService: BrowserTitle,
    private settingsService: SettingsService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.headerLabel = this._headerLabel;
    });
  }

  public hasHeaderLabelParams(): boolean {
    return Object.keys(this.headerLabelParams || {}).length > 0;
  }

  public reset(): void {
    this.headerLabel = Title.Default;
    this.headerLabelParams = {};
    this.subHeader = null;
  }

  private regenerateTitle(): void {
    if (!this._headerLabel || this._headerLabel === Title.Default) {
      return this.titleService.setTitle(this.settingsService.frontEnv?.appName);
    }

    this.translateService
      .get(this._headerLabel, this.headerLabelParams)
      .subscribe(translatedValue => {
        if (this.hasHeaderLabelParams()) {
          this.titleService.setTitle(this.settingsService.frontEnv?.appName + ' - ' + translatedValue);
        } else {
          this.titleService.setTitle(this.settingsService.frontEnv?.appName);
        }
      });
  }

}
