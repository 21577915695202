import { getAnswerForType } from '../../AnswerValidator';
import { AnswerType } from '../../enums/AnswerType';
import { AbstractQuestionEntity } from './AbstractQuestionEntity';

export abstract class AbstractChoiceQuestionEntity extends AbstractQuestionEntity {
  public showOneAnswerPerRow: boolean;

  protected constructor(settingsService, props) {
    super(settingsService, props);

    this.showOneAnswerPerRow = settingsService.frontEnv?.defaultQuizSettings.question.showOneAnswerPerRow;
    this.showOneAnswerPerRow = props.showOneAnswerPerRow ? props.showOneAnswerPerRow : this.showOneAnswerPerRow;
    this.answerOptionList = this.answerOptionList.map(answer => {
      return getAnswerForType(settingsService, AnswerType.DefaultAnswerOption, answer);
    });
  }

  public isParentValid(): boolean {
    return super.isValid();
  }
}
