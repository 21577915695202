<div class="modal-header">
    <h4 class="modal-title">Message of the Day</h4>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <strong>{{'component.motd.modal_title' | translate: {amount: motdData.unseenMotds.length} }}</strong>
    <hr>
    <ng-container *ngFor="let motd of motdData?.unseenMotds">
        <div class="card shadow-sm mb-5" >
            <div class="card-body">
                <h5 class="card-title">
                    {{motd.title}}
                    <span *ngIf="motd.isPinned" class="badge badge-warning">{{'component.motd.pinned_badge_text' | translate}}</span>
                </h5>
                <div class="card-text" markdown [innerHTML]="compileMarkDownContent(motd.content)"></div>
                <hr>
                <div class="d-flex justify-content-end text-right">
                    <span>{{motd.createdAt | date:'dd.MM.yyyy'}}</span>
                </div>
            </div>
        </div>
        <hr>
    </ng-container>
</div>
