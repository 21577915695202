import { IMemberGroupBase } from '../../interfaces/users/IMemberGroupBase';

export class NickSessionConfigurationEntity {
  public memberGroups: Array<IMemberGroupBase>;
  public maxMembersPerGroup: number;
  public autoJoinToGroup: boolean;
  public selectedNicks: Array<string>;
  public blockIllegalNicks: boolean;

  constructor(settingsService, props) {
    this.memberGroups = props.memberGroups ?? settingsService.frontEnv?.defaultQuizSettings.sessionConfig.nicks.memberGroups;
    this.maxMembersPerGroup = props.maxMembersPerGroup ?? settingsService.frontEnv?.defaultQuizSettings.sessionConfig.nicks.maxMembersPerGroup;
    this.autoJoinToGroup = props.autoJoinToGroup ?? settingsService.frontEnv?.defaultQuizSettings.sessionConfig.nicks.autoJoinToGroup;
    this.selectedNicks = props.selectedNicks ?? settingsService.frontEnv?.defaultQuizSettings.sessionConfig.nicks.selectedNicks;
    this.blockIllegalNicks = props.blockIllegalNicks ?? settingsService.frontEnv?.defaultQuizSettings.sessionConfig.nicks.blockIllegalNicks;
  }

  public equals(value: NickSessionConfigurationEntity): boolean {
    return this.memberGroups === value.memberGroups && //
           this.maxMembersPerGroup === value.maxMembersPerGroup && //
           this.autoJoinToGroup === value.autoJoinToGroup && //
           this.selectedNicks === value.selectedNicks && //
           this.blockIllegalNicks === value.blockIllegalNicks;
  }

  public hasSelectedNick(nickname: string): boolean {
    return this.selectedNicks.indexOf(nickname) !== -1;
  }

  public toggleSelectedNick(nickname: string): void {
    if (this.hasSelectedNick(nickname)) {
      this.removeSelectedNickByName(nickname);
    } else {
      this.addSelectedNick(nickname);
    }
  }

  public addSelectedNick(newSelectedNick: string): void {
    if (this.hasSelectedNick(newSelectedNick)) {
      return;
    }
    this.selectedNicks.push(newSelectedNick);
  }

  public removeSelectedNickByName(selectedNick: string): void {
    const index = this.selectedNicks.indexOf(selectedNick);
    if (index === -1) {
      return;
    }
    this.selectedNicks.splice(index, 1);
  }
}
