import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { StorageKey } from '../../lib/enums/enums';
import { OutdatedVersionComponent } from '../../modals/outdated-version/outdated-version.component';
import {SettingsService} from '../settings/settings.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OutdatedVersionGuardService implements CanActivate {
  private _versionMismatch: boolean;
  private _targetVersion: string;
  private _currentVersion: string;

  constructor(
      @Inject(PLATFORM_ID) private platformId: Object,
      private http: HttpClient,
      private ngbModal: NgbModal,
      private settingsService: SettingsService
  ) { }

  public canActivate(): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }

    return this.settingsService.settingsLoadedEmitter.pipe(
      switchMap(status => {
        console.log('settings loaded', status);
        const storedVersion = sessionStorage.getItem(StorageKey.OutdatedVersionFunnelStep)?.trim() ?? null;

        if (!status || (storedVersion !== null && isNaN(parseInt(storedVersion, 10)))) {
          return throwError(true);
        }
        this._currentVersion = storedVersion !== null && isNaN(parseInt(storedVersion, 10)) ? storedVersion : environment.version;

        return of(true);
      }),
      switchMap(() => {
        console.log('fetching version.txt');
        return this.http.get<string>(
          '/assets/version.txt',
          {
            responseType: 'text' as any,
            headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
              'Pragma': 'no-cache',
              'Expires': '0',
            },
          }
        );
      }),
      switchMap(version => {
        this._targetVersion = version.trim();
        this._versionMismatch = this._targetVersion !== this._currentVersion;

        console.log(this._versionMismatch, this._targetVersion, this._currentVersion);
        if (!this._versionMismatch) {
          sessionStorage.setItem(StorageKey.OutdatedVersionFunnelStep, this._targetVersion);
          return throwError(true);
        }

        this.openModal().result.finally(() => {
          console.log('close modal');
          return throwError(true);
        });
      }),
      catchError(err => {
        console.log('proceeding with route loading');
        return of(true);
      }),
    );
  }

  private openModal(): NgbModalRef {
    const ref = this.ngbModal.open(OutdatedVersionComponent, { beforeDismiss: () => false });
    ref.componentInstance.currentVersion = this._currentVersion;
    ref.componentInstance.targetVersion = this._targetVersion;
    return ref;
  }
}
